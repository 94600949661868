import dynamic from 'next/dynamic';
import type { FunctionComponent } from 'react';

import { useAuth } from '@/customerManagement/auth/hooks/useAuth';

const AnonymousAccount = dynamic(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/HeaderLeonidas/b2c/components/AnonymousAccount/AnonymousAccount.default'
      )
    ).AnonymousAccount,
  { ssr: true },
);

const ConnectedAccount = dynamic(
  async () =>
    (
      await import(
        '@/domains/productDiscovery/HeaderLeonidas/b2c/components/ConnectedAccount/ConnectedAccount'
      )
    ).ConnectedAccount,
  { ssr: true },
);

interface Props {
  className?: string;
}

export const Account: FunctionComponent<Props> = ({ className }) => {
  const { isAuthenticated } = useAuth();

  return (
    <div className={className}>
      {isAuthenticated ? <ConnectedAccount /> : <AnonymousAccount />}
    </div>
  );
};
